//use 'var' instead of 'window.anwaltDeApp', because during automated testing, the window-variable may not be set
window.anwaltDeApp = window.anwaltDeApp || {};

window.addEventListener('load', function () {
  /* iPad landscape fix
   ----------------------------------------------------*/
  if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) {
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      viewportMeta.content = 'width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0';
      document.body.addEventListener(
        'gesturestart',
        function () {
          viewportMeta.content = 'width=device-width, minimum-scale=0.25, maximum-scale=1.6';
        },
        false
      );
    }
  }

  let sortSelection = document.querySelector('#sortSelection.jsSortSelection');

  if (sortSelection) {
    sortSelection.addEventListener('change', function (event) {
      event.preventDefault();
      window.location.href = sortSelection.value;
      return false;
    });
  }

  const forms = document.querySelectorAll(
    '#profile-write-message-form, #message-user-creation-form, #profile-rating-form, ' +
      '#profile-rating-user-transaction-form, #premium-registration-form, #basic-registration-form'
  );
  forms.forEach(function (form) {
    form.addEventListener('submit', function (event) {
      const waitingTime = 30 * 1000; // 25 seconds
      const submitButton = event.target.querySelector('[name=submitForm]');

      submitButton.disabled = true;
      window.setTimeout(function () {
        submitButton.disabled = false;
      }, waitingTime);
    });
  });
});
